import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
/* 状态管理  左侧导航开关   用户信息保存 */
const store = new Vuex.Store({
  state: {
    // 菜单展开收缩
    isCollapse:
      JSON.parse(sessionStorage.getItem("isCollapse")) == null
        ? false
        : JSON.parse(sessionStorage.getItem("isCollapse")),
    // 数据研判控制样式
    openNewUrl: JSON.parse(sessionStorage.getItem("openNewUrl")),
    // 系统设置选中标签
    activeName:
      sessionStorage.getItem("activeName") == null
        ? "基本信息"
        : sessionStorage.getItem("activeName"),
    // 专项行动详情选中标签
    activeNameSpecial:
      sessionStorage.getItem("activeNameSpecial") == null
        ? "generalization"
        : sessionStorage.getItem("activeNameSpecial"),
    // 系统设置中我的收藏选中标签
    myFavoriteActiveName:
      sessionStorage.getItem("myFavoriteActiveName") == null
        ? "有害信息"
        : sessionStorage.getItem("myFavoriteActiveName"),
    // 专项行动生成报告请求参数
    dataListTopic: {},
    prodLogo: JSON.parse(window.localStorage.getItem("prod")||'{}')||{}
  },
  mutations: {
    sedData(state, payload) {
      state.isCollapse = payload;
      sessionStorage.setItem("isCollapse", JSON.stringify(state.isCollapse));
    },
    setOpenNewUrl(state) {
      state.openNewUrl = true;
      sessionStorage.setItem("openNewUrl", JSON.stringify(state.openNewUrl));
    },
    setOpenNewUrlAgin(state) {
      state.openNewUrl = false;
      sessionStorage.setItem("openNewUrl", JSON.stringify(state.openNewUrl));
    },
    setProdLogo(state, payload){
      state.prodLogo = payload;
      window.localStorage.setItem("prod", JSON.stringify(state.prodLogo));
    }
  },
});
export default store;
