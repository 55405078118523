<template>
  <div class="header">
    <div class="back-ground">
      <!-- <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.1" />
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.3)" />
        </g>
      </svg> -->
    </div>
    <div class="content">
      <img style="margin-left: 30px; margin-right: 30px" src="@/assets/img/header/fold.png" @click="change" alt="" v-if="
          $route.path != '/bigdata/harmfulInformation' &&
            $route.path != '/bigdata/misrepresentation' &&
            $route.path != '/bigdata/exceptionFilter' &&
            !$store.state.isCollapse
        " />
      <img style="margin-left: 30px; margin-right: 30px" src="@/assets/img/header/unfold.png" @click="change" alt="" v-if="
          $route.path != '/bigdata/harmfulInformation' &&
            $route.path != '/bigdata/misrepresentation' &&
            $route.path != '/bigdata/exceptionFilter' &&
            $store.state.isCollapse
        " />
      <div :class="
          $route.path != '/bigdata/harmfulInformation' &&
          $route.path != '/bigdata/misrepresentation' &&
          $route.path != '/bigdata/exceptionFilter'
            ? 'box'
            : 'boxTwo'
        ">
        <div class="box-title">{{ systemName }}</div>
        <div class="box-desc">{{ myinfo.orgName }}</div>
      </div>
      <div class="boxRight">
        <div @click="goDownload">
          <el-badge :value="downloadCount" :max="99" :hidden="downloadCount==0" class="download">
            <img src="../assets/img/header/xiazai.png" alt="">
          </el-badge>
        </div>
        <template v-if="!isWangxinban">
          <!-- 已停用 -->
          <img v-if="prod.prodType == -1" class="img" src="../assets/img/gjh/ty.png" alt="" srcset="" />
          <!-- 试用 -->
          <img v-if="prod.prodType == 0" class="img" src="../assets/img/gjh/sy.png" />
          <!-- 正式 -->
          <img v-if="prod.prodType == 1" class="img" src="../assets/img/gjh/zs.png" />
        </template>
          <el-dropdown>
            <div class="boxRight-center m-l-24 m-r-30">
              <div>{{ myinfo.name }}，欢迎登录 ！</div>
              <div>
                <p>{{ dataTime }}</p>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-show="myinfo.roleId!==1" @click.native="passWordOpen">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        <!-- <div class="boxRight-back">
          <img @click="logout" style="margin-left: 30px; margin-right: 30px" src="../assets/img/header/quit.png" alt="" />
        </div> -->
      </div>
      <!-- 弹框 -->
      <DeleteDialog ref="openDialog" @deleteFn="dataDelete" />
      <!-- 修改密码 -->
      <PassDialog ref="passDialog" @updatePassWord='updatePassWord'></PassDialog>
    </div>
  </div>
</template>

<script>
import { logout,updatePassWord } from "../api/login/index";
import { getDownloadCount } from "../api/download/index";
import DeleteDialog from "./deleteDialog.vue";
import PassDialog from "./passDialog.vue";
export default {
  components: { DeleteDialog,PassDialog },
  data() {
    return {
      // 头部时间
      dataTime: null,
      myinfo: {},
      systemName: "",
      // prod: {},
      isWangxinban: true,
      downloadCount: '',
    };
  },
  created() {
    this.myinfo = JSON.parse(window.localStorage.getItem("user"));
    this.systemName = window.localStorage.getItem("systemName");
    this.getTime();
    // this.getInfo()
    this.isWangxinban = JSON.parse(window.localStorage.getItem('isWangxinban'))
    //正式/试用图标展示依据

    // console.log(this.prod.prodType,'sadsadsa');

    this.getDownloadCount()
    //轮询
    this.againDownloadCount()
  },
  async mounted() {
    // this.prod = this.$store.state.prodLogo
  },
  computed:{
    prod(){
      return this.$store.state.prodLogo
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    againDownloadCount() {
      this.timer = setInterval(() => {
        this.getDownloadCount()
        // this.downloadCount++
      }, 10000);
    },
    async getDownloadCount() {
      let res = await getDownloadCount()
      if (res.data.code == 200) {
        this.downloadCount = res.data.data
      }
    },
    goDownload() {
      // this.$main.routeResolve('/download')
      this.$router.push('/download')
    },
    getInfo() {
      this.prod = JSON.parse(window.localStorage.getItem("prod"));
      // console.log(prod.prodType);
    },
    // 获取本地时间
    getTime() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let hours = date.getHours();
      hours = hours >= 10 ? hours : "0" + hours;
      let minutes = date.getMinutes();
      minutes = minutes >= 10 ? minutes : "0" + minutes;
      let seconds = date.getSeconds();
      seconds = seconds >= 10 ? seconds : "0" + seconds;
      this.dataTime = y + "年" + m + "月" + d + "日  " + hours + ":" + minutes + ":" + seconds;
      setTimeout(() => {
        this.getTime();
      }, 1000);
    },
    change() {
      this.$emit("change");
    },
    //  退出系统
    logout() {
      this.$refs.openDialog.openDialog({
        logout: true,
      });
    },
    // 退出
    async dataDelete() {
      const Res = await logout();
      const { code, msg } = Res.data;
      if (code == 200) {
        this.$router.push({ path: "/" });
        window.localStorage.clear();
        window.sessionStorage.clear();
      } else {
        this.$message.warning(msg);
      }
    },
    passWordOpen(){
      this.$refs.passDialog.dialogVisible = true
      this.$refs.passDialog.oldPassWord = ''
      this.$refs.passDialog.newPassWord = ''
    },
    // 修改密码
    async updatePassWord(data) {
      // console.log(data);
      // let data = {
      //   "phoneNum": this.phone,
      //   "password": this.oldPassWord,
      //   "newPassword": this.newPassWord
      // }
      // if (!data.phoneNum) {
      //   this.$message.warning('请输入手机号')
      //   return
      // }
      // if (data.phoneNum) {
      //   let pattern = /^1[3456789]\d{9}$/;
      //   if (!pattern.test(data.phoneNum)) {
      //     this.$message.warning('请输入正确的手机号')
      //     return
      //   }
      // }
      if (!data.password) {
        this.$message.warning('请输入旧密码')
        return
      }
      if (!data.newPassword) {
        this.$message.warning('请输入新密码')
        return
      }
      if (data.newPassword) {
        // let pattern = /^(?=.[a-zA-Z])(?=.\d)[a-zA-Z\d]{8,16}$/;
        // let pattern = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;
        let pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,16}$)/;
        if (!pattern.test(data.newPassword)) {
          this.$message.warning('密码由大于8位小于16位的数字字母组成')
          return
        }
      }
      this.loading = true
      let res = await updatePassWord(data)
      if (res?.data?.code == 200) {
        this.$message.success('修改成功')
        this.$refs.passDialog.dialogVisible = false
      }else{
        this.$message.warning(res.data.msg)
        this.$refs.passDialog.dialogVisible = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// @font-face {
//   font-family: "fz";
//   src: url("../assets/font/FZZCHJW.otf");
//   font-weight: normal;
//   font-style: normal;
// }
.header {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 2100;
  background: url(../assets/img/header/heards.png) no-repeat center/108% 115%;
  padding: 0;
  // background-size: 150% 102%;
  /* min-width: 1625px; */
  .back-ground {
    width: 100%;
    height: 100%;
    .waves {
      position: relative;
      width: 100%;
      height: 100%; //15vh
      margin-bottom: -7px;
      /*Fix for safari gap*/
      // min-height: 100px;
      max-height: 150px;
    }

    /* Animation */

    .parallax > use {
      animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    }

    .parallax > use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }

    .parallax > use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }

    .parallax > use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }
  }
  .content {
    position: absolute;
    z-index: 2101;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    min-width: 1625px;
    img {
      cursor: pointer;
    }
    .title {
      width: 400px;
      font-size: 30px;
      font-family: "fz";
      font-weight: normal;
      color: #ffffff;
      margin-left: 20px;
    }
    .operation {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(100% - 507px);
      height: 100%;
      // margin-left: calc(100% - 1019px);
      .header-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
      .dropdown {
        margin-left: 10px;
        .el-dropdown-link.el-dropdown-selfdefine {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
    .box {
      width: 380px;
      height: 60px;
      border-left: 1px solid #ffffff;
      position: relative;
      padding-left: 32px;
      // display: flex;
      // flex-direction: column;
      // justify-content:space-between;
      // align-items: center;
      color: #ffffff;
      .box-title {
        position: absolute;
        font-size: 30px;
        font-family: "fz";
        top: -6px;
      }
      .box-desc {
        position: absolute;
        bottom: 0;
        font-size: 16px;
      }
    }
    .boxTwo {
      width: 380px;
      height: 60px;
      position: relative;
      padding-left: 32px;
      // display: flex;
      // flex-direction: column;
      // justify-content:space-between;
      // align-items: center;
      color: #ffffff;
      .box-title {
        position: absolute;
        font-size: 30px;
        font-family: "fz";
        top: -6px;
      }
      .box-desc {
        position: absolute;
        bottom: 0;
        font-size: 16px;
      }
    }
    .boxRight {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      text-align: right;
      color: #ffffff;
      .download {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 24px;
        margin-top: 8px;
      }
      .img {
        width: 110px;
        height: 40px;
      }
      .boxRight-center {
        width: auto;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #ffffff;
      }
    }
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }

    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
  }
}
::v-deep .el-dropdown-menu__item {
  display: flex;
  align-items: center;
}
::v-deep .el-dropdown-menu__item:hover {
  background: #f5f6f7;
  color: #606266 !important;
}
</style>
